import _ from 'lodash';

const REDIRECT_PATH = {
    "purchased": "/v2/shippingAndPromptpay",
    "register_form": "/v2/register",
    "main": "/v2/home"
}

export default defineNuxtRouteMiddleware((to, from) => {
    const event = useEventStore().event;
    if (event?.pages?.length > 0) {
        let pageName: string = _.first(event.pages);
        let targetPath = REDIRECT_PATH[pageName as keyof typeof REDIRECT_PATH];
        if (to.path !== targetPath) {
            return navigateTo(targetPath);
        }
    }
})
