import validate from "/Users/utumpornnaikorn/mywork/Niceloop-project2/photowish-production-v2/photowish-connect-liff/node_modules/nuxt/dist/pages/runtime/validate.js";
import pages_45global from "/Users/utumpornnaikorn/mywork/Niceloop-project2/photowish-production-v2/photowish-connect-liff/middleware/pages.global.ts";
import manifest_45route_45rule from "/Users/utumpornnaikorn/mywork/Niceloop-project2/photowish-production-v2/photowish-connect-liff/node_modules/nuxt/dist/app/middleware/manifest-route-rule.js";
export const globalMiddleware = [
  validate,
  pages_45global,
  manifest_45route_45rule
]
export const namedMiddleware = {
  auth: () => import("/Users/utumpornnaikorn/mywork/Niceloop-project2/photowish-production-v2/photowish-connect-liff/middleware/auth.ts")
}